<template>
    <section class="content">
        <div class="w-100 card">
        <div class="card-body">
			<h3 style="text-align: center;">Daftar Nilai Siswa</h3>
			<table>
				<tr>
					<td>Kelas</td>
					<td style="text-align: center;width: 15%;">:</td>
					<td>{{info.kelas}}</td>
				</tr>
				<tr>
					<td>Mata Pelajaran</td>
					<td style="text-align: center;width: 15%;">:</td>
					<td>{{info.mapel}}</td>
				</tr>
				<tr style="display: none;">
					<td>Nama Tentor</td>
					<td style="text-align: center;width: 15%;">:</td>
					<td></td>
				</tr>
				<tr style="display: none;">
					<td>Hari/Tanggal</td>
					<td style="text-align: center;width: 15%;">:</td>
					<td></td>
				</tr>
			</table>
        </div>
        </div>
        <div class="p-1"></div>
        <table class="table table-hover" ref="tablesuswa" style="width: 100%;">
            <thead>
                <tr>
                    <th rowspan="2" style="width:70px;vertical-align: middle;">NO</th>
                    <th rowspan="2" style="width:60px;vertical-align: middle;">NAMA SISWA</th>
                    <th colspan="20" style="text-align: center;">PENILAIAN</th>
                </tr>
                <tr class="asd">
                	<th v-for="n in 20" style="padding: 10px;">{{n}}</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import moment from "moment";
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'DetailHasilUjianSiswa',
    data() {
        return {
            errors: [],
            siswa: {},
            info: [],
            method: '',
            roles: '',
            idKelas: '',
            idMapel: '',
            idProgram: '',
            branch: ''
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        this.idMapel = this.$route.params.mapel_id
        this.idKelas = this.$route.params.kelas_id
        this.idProgram = this.$route.params.program_id
        authFetch("/akademik/ujian/rekap_info/" + this.idKelas + '/' + this.idMapel + '/' + this.idProgram).then((res) => {
        res.json().then((json) => {
            this.info = json;
            console.log(this.info)
        });
    });
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        var date = moment().format("YYYY-MM-DD");

        this.table = createTable(e.tablesuswa, {
            "title": "List Data",
            "roles": ["read"],
            "ajax": "/akademik/ujian/rekap_hasil_ujian",
            scrollX: true,
            serverSide: false,
            filter:false,
            sort: false,
            rowCallback: function(row, data) {
            },
            paramData: function ( d ) {
                d.mapel_id = self.$route.params.mapel_id
                d.kelas_id = self.$route.params.kelas_id
                d.program_id = self.$route.params.program_id
                d.type = 'latihan'
            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
                    },
                    title: function(){
                        return 'Daftar Latihan Nilai Siswa';
                    },
                    filename: function(){
                        return 'Daftar Latihan Nilai Siswa - ' + date;
                    },
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Daftar Latihan Nilai Siswa';
                    },
                    filename: function(){
                        return 'Daftar Latihan Nilai Siswa - ' + date;
                    }
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Daftar Latihan Nilai Siswa';
                    },
                    filename: function(){
                        return 'Daftar Latihan Nilai Siswa - ' + date;
                    },
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
                    },
                    title: function(){
                        return 'Daftar Latihan Nilai Siswa';
                    },
                    filename: function(){
                        return 'Daftar Latihan Nilai Siswa - ' + date;
                    },
                },
            ],
        });
        // authFetch("/akademik/ujian/rekap_hasil_ujian_header/" + this.idKelas + '/' + this.idMapel + '/' + this.idProgram)
        // .then((res) => {
        //     res.json().then((json) => {
        //         var data = json.map(function(val, key) {
        //             // var rotate = val.length > 2 ? 'transform:rotate(270deg)':'';
        //             // $('.asd').append(`<th style="padding: 10px;height:80px;vertical-align: middle;width: 20px !important" class="sorting_disabled" rowspan="1" colspan="1"><p style="width: max-content;`+rotate+`">`+val+`</p></th>`);
        //         })
        //         if (data) {
        //         }
        //     });
        // })

    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
</style>